import React, { useState, useRef, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.js";
import searchline from "../img/search-line.png";
import educationlogo from "../img/educationlogo.png";
import signin from "../img/signin.png";
import { FaAlignRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import nayeedishalogosfin from "../img/nayeedishalogos-fin.svg";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import searchicon from "./search-icon.png";
import ReactSearchBox from "react-search-box";
import user from "./user.svg";
import topi1 from "./lets-icons_send-fill.svg";
import navright from "./navright.png";
import topi2 from "./basil_login-solid.svg";
import topi3 from "./lets-icons_send-fill.svg";
import facebook from "./facebook.svg";
import twitter from "./twitter.svg";
import { FaXTwitter } from "react-icons/fa6";
import linkedin from "./linkedin.svg";
import instagram from "./instagram.svg";
import whatsapp from "./whatsapp.svg";
import youtube from "./youtube.svg";
import nayeed from "./nayee-d.png";
import CallBackRequest from "../pages/request-callback/index"
import { useHistory } from 'react-router-dom';
import PhoneInput from 'react-phone-input-2'
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import { AiFillCaretRight } from "react-icons/ai";
import Swal from 'sweetalert2';
import apiUrl from '../setting/apiConfig'
import ApplyNow from '../components/applynow.svg'
import { useLocation } from 'react-router-dom';


const Header = ({ isMobile, handleToggleSidebar }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [submenuOpen, setSubmenuOpen] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [russiaUniversities, setRussiaUniversities] = useState([]);
  const [nepalUniversities, setNepalUniversities] = useState([]);

  const location = useLocation();  // useLocation instead of global location
  const [courseData, setCourseData] = useState(null);

  const [phoneNumber, setPhoneNumber] = useState(''); // State for phone input
  // Extract course query parameter from the URL
  const params = new URLSearchParams(location.search);
  const course = params.get('course');

  useEffect(() => {
    if (course) {
      const apiUrl = `https://webcrmapi/api/course-details`;

      fetch(apiUrl)
        .then((response) => response.json())
        .then((data) => {
          setCourseData(data);
        })
        .catch((error) => {
          console.error('Error fetching course data:', error);
        });
    }
  }, [course]);


  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleDropdownClose = () => {
    setDropdownOpen(false);
  };
  // Function to handle toggling the submenu
  const handleSubmenuToggle = () => {
    setSubmenuOpen(!submenuOpen);
  };

  const navigate = useLocation();

const handleCourseClick = (courseName) => {
  navigate('/course', { state: { course: courseName } });
};


  const [isHovered, setIsHovered] = useState(false);
  const [isDropdownHovered, setIsDropdownHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleDropdownMouseEnter = () => {
    setIsDropdownHovered(true);
  };

  const handleDropdownMouseLeave = () => {
    setIsDropdownHovered(false);
  };


  // State for the new dropdown and submenu
  const [newDropdownOpen, setNewDropdownOpen] = useState(false);
  const [newSubmenuOpen, setNewSubmenuOpen] = useState(false);

  // State for hover effects on the new dropdown
  const [newIsHovered, setNewIsHovered] = useState(false);
  const [newIsDropdownHovered, setNewIsDropdownHovered] = useState(false);

  // Event handlers for the new dropdown and submenu
  const handleNewDropdownToggle = () => {
    setNewDropdownOpen(!newDropdownOpen);
  };

  const handleNewDropdownClose = () => {
    setNewDropdownOpen(false);
  };

  const handleNewSubmenuToggle = () => {
    setNewSubmenuOpen(!newSubmenuOpen);
  };

  const handleNewMouseEnter = () => {
    setNewIsHovered(true);
  };

  const handleNewMouseLeave = () => {
    setNewIsHovered(false);
  };

  const handleNewDropdownMouseEnter = () => {
    setNewIsDropdownHovered(true);
  };

  const handleNewDropdownMouseLeave = () => {
    setNewIsDropdownHovered(false);
  };

  const history = useHistory();
  const [loading, setLoading] = useState(true);
  const [isloading, setIsLoading] = useState(true);

  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [universities, setUniversities] = useState([]);
  const formRef = useRef(null);


  // show university according country
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);

        const universityResponse = await fetch(apiUrl + 'university-details');
        const universityData = await universityResponse.json();

        // Filter universities for Russia and Nepal
        const russiaFiltered = universityData.filter(university => university.country === 'Russia');
        const nepalFiltered = universityData.filter(university => university.country === 'Nepal');

        // Update state with filtered data
        setRussiaUniversities(russiaFiltered);
        setNepalUniversities(nepalFiltered);

        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  // Close

  useEffect(() => {
    if (successMessage) {
      Swal.fire({
        title: "Success!",
        text: successMessage,
        icon: "success"
      });
    }
  }, [successMessage]);

  useEffect(() => {
    if (errorMessage) {
      Swal.fire({
        title: "Error!",
        text: errorMessage,
        icon: "error"
      });
    }
  }, [errorMessage]);



  const submitForm = async (e) => {
    e.preventDefault();
    var data = new FormData(e.target);
    let formData = Object.fromEntries(data.entries());

    try {
      setLoading(true);

      const response = await fetch('https://webcrmapi.nayeedisha.in/api/request-callback', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.error || 'Failed to add Sent Message');
      }

      setLoading(false);
      setSuccessMessage(responseData.message);
      setErrorMessage(null);
      history.push("/");
      formRef.current.reset();

      // Show success message using SweetAlert2
      Swal.fire({
        title: "Success!",
        text: responseData.message,
        icon: "success"
      });

      // Hide success message after 5 seconds
      setTimeout(() => {
        setSuccessMessage(null);
      }, 5000);
      setPhoneNumber(''); // Clear phone input
      formRef.current.reset(); // Reset the entire form
    } catch (error) {
      console.error(error);
      setLoading(false);
      setSuccessMessage(null);
      setErrorMessage(error.message);

      // Show error message using SweetAlert2
      Swal.fire({

        title: "Error!",
        text: 'error.message ',
        icon: "error",
        showConfirmButton: false,
        timer: 1500
      });
    }
  };





  const desktopmenu = () => {
    //



    //
    return (
      <div>
        {/* request callback */}
        <div className="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h3 className="modal-title anifont" id="exampleModalLongTitle">Request a call back</h3>
                <button type="button" className="close btn btn-danger" data-dismiss="modal" aria-label="Close">
                <i class="fa fa-times" aria-hidden="true"></i>
                </button>
              </div>
              <div className="modal-body">
                <form ref={formRef} onSubmit={submitForm} className="mx-3">
                  <div id="form" className='pt-3'>



                    <div className="form-group">
                      <InputGroup className="mb-3" type="text">
                        {/* <InputGroup.Text id="basic-addon1">@</InputGroup.Text> */}
                        <Form.Control className='p-2'
                          placeholder="Full Name"
                          aria-label="Username"
                          aria-describedby="basic-addon1"
                          name='name'
                          required
                        />
                      </InputGroup>


                    </div>







                    <div className="form-group mt-3  ph-forms  ">
                      <span className="wpcf7-form-control-wrap your-name ">
                        {/* <input name='phone_number' className='p-2' placeholder=" Phone Number" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" type="number" maxlength="10" required=""

                      /> */}
                        <PhoneInput inputProps={{
                          name: 'phone_number', required: true, autoFocus: true
                        }}
                          country={'in'}
                          value={phoneNumber}
                          onChange={(phone) => setPhoneNumber(phone)}
                        
                        />


                      </span>
                    </div>





                    <div className="form-group mt-3  ph-forms  ">
                      <span className="wpcf7-form-control-wrap your-name ">

                        <textarea className="p-2" name='message' placeholder=' Message' rows={5} cols={66} />



                      </span></div>


                  </div>

               



                  <div className='mt-3 fr-but'>
                    <input type="submit" value='Submit' className="btn btn-primary btn-accent" />
                  </div>

                </form>
                <br></br>
              </div>

            </div>
          </div>
        </div>

        {/* close */}
        {/* upper top header start */}
        <div className="upper-header">
          <div className="container">
            <div className="row">
              <div className="col-md-8">
                <div className="d-flex header-toppadding">

                  <div
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    data-bs-whatever="@mdo"
                  > <p className="coursor">

                      <img src={topi1} alt="Nayeedisha" style={{ width: "" }} /> Apply
                      Online
                    </p>
                  </div>
                  &nbsp;&nbsp;&nbsp;

                  <span onClick={() => setModalShow(true)}>


                    <p data-toggle="modal" data-target="#exampleModalCenter" className="coursor">

                      <img src={topi3} alt="Nayeedisha" style={{ width: "" }} /> Request a
                      call back 
                    </p>
                  </span>
                </div>
              </div>
              <div className="col-md-4 text-end mt-2 ">
                <div>
                  <a href="https://www.facebook.com/people/NayeeDisha-Study-Abroad/61559962673504/" target="_blank"><img src={facebook} alt="Nayeedisha" style={{ width: "" }} /></a>
                  &nbsp;&nbsp;&nbsp;
                  <a href="https://x.com/NayeeInfo" target="_blank"><FaXTwitter className="text-white" /></a>
                  &nbsp;&nbsp;&nbsp;
                  <a href="https://www.linkedin.com/company/nayeedishaeducation/" target="_blank">  <img src={linkedin} alt="Nayeedisha" style={{ width: "" }} /></a>
                  &nbsp;&nbsp;&nbsp;
                  <a href="https://wa.me/+918860031900" target="_blank"><img src={whatsapp} alt="Nayeedisha" style={{ width: "" }} /></a>
                  &nbsp;&nbsp;&nbsp;

                  <a href="https://www.instagram.com/nayeedisha_official/?hl=en" target="_blank"><img src={instagram} alt="Nayeedisha" style={{ width: "" }} /></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*  */}
        {/* top header start */}
        <div className="top-header-2 pt-1">
          <div className="container">
            <div className="row">
              <div className="col-md-4 d-flex pt-2">
                <a className="navbar-brand" href="/">
                  {/* <img src={nayeed} alt="" style={{ width: "" }} /> */}
                  <img
                    src={nayeedishalogosfin}
                    alt="logo"
                    style={{ width: "200px" }}
                  />
                </a>
              </div>
              <div className="col-md-8 d-flex justify-content-end pt-3 pb-3">
                <div className=" d-flex ">
                  <div className="pt-2 svgrs icon-width">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23"
                      height="29"
                      fill="currentColor"
                      class="bi bi-telephone"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                    </svg>
                    &nbsp;
                  </div>
                  <a href="tel:+91 8860031900"><div className="t-hc pt-1">
                    <p className="p-0 m-0 callnow_newcolor"> Call Us Now</p>
                    <p className="p-0"> +91 8860031900</p>
                  </div>
                  </a>
                  <div className="firster"></div>
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div className=" d-flex">
                  <div className="pt-2 svgrs2 icon-width">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="23"
                      height="29"
                      fill="currentColor"
                      className="bi bi-whatsapp"
                      viewBox="0 0 16 16"
                    >
                      <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                    </svg>
                    &nbsp;
                  </div>
                  <a href="https://wa.me/+918860031900" target="_blank"><div className="t-hc pt-1">
                    <p className="p-0 m-0 whatsapp_newcolor"> Whatsapp Us</p>
                    <p className="p-0"> +91 8860031900</p>
                  </div>
                  </a>
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div className=" divider-crds-desk pt-1">
                  <div
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModal"
                    data-bs-whatever="@mdo"
                  >
                    <span className="anibutton">
  Apply Now
  <svg fill="currentColor" viewBox="0 0 24 24" className="icon">
    <path clip-rule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm4.28 10.28a.75.75 0 000-1.06l-3-3a.75.75 0 10-1.06 1.06l1.72 1.72H8.25a.75.75 0 000 1.5h5.69l-1.72 1.72a.75.75 0 101.06 1.06l3-3z" fill-rule="evenodd"></path>
  </svg>
</span>
                    {/* <button type="button" className="btn wave">
                      <span>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          className="bi bi-headset"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 1a5 5 0 0 0-5 5v1h1a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V6a6 6 0 1 1 12 0v6a2.5 2.5 0 0 1-2.5 2.5H9.366a1 1 0 0 1-.866.5h-1a1 1 0 1 1 0-2h1a1 1 0 0 1 .866.5H11.5A1.5 1.5 0 0 0 13 12h-1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h1V6a5 5 0 0 0-5-5" />
                        </svg>
                      </span>
                      &nbsp;&nbsp;&nbsp; Live Counselling
                    </button> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* top header end */}
        <div className="header-next-2">
          <nav className="navbar navbar-expand-lg navbar-light  p-0">
            <div className="container">
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
                    <path
                      stroke="rgba(255, 255, 255, 0.55)"
                      stroke-linecap="round"
                      stroke-miterlimit="10"
                      stroke-width="2"
                      d="M4 7h22M4 15h22M4 23h22"
                    />
                  </svg>
                </span>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav mb-lg-0">
                  <li class="nav-item border-rightfornav nav-padding">
                    <a
                      class="nav-link active "
                      aria-current="page"
                      href="/"
                      id="naver-link"
                    >
                      Home
                    </a>
                  </li>

                  <Link to={"/about-us"}>
                    <li class="nav-item plx-4 border-rightfornav nav-padding">
                      <a
                        class="nav-link "
                        aria-current="page"
                        href="/about-us"
                        id="naver-link"
                      >

                        About Nayee Disha
                      </a>
                    </li>
                  </Link>
                  <Link to={"/services"}>
                    <li class="nav-item plx-4 border-rightfornav nav-padding">
                      <a
                        class="nav-link "
                        aria-current="page"
                        href="#"
                        id="naver-link"
                      >
                        {" "}
                        Our Services{" "}
                      </a>
                    </li>
                  </Link>
                  <li className="nav-item dropdown plx-4 border-rightfornav nav-padding"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <div className="dropdown-wrapper">
                      <a
                        className="nav-link dropdown-toggle text-white"
                        href="/study-abroad"
                        id="navbarDropdown"
                        role="button"
                        aria-expanded={isHovered || isDropdownHovered ? "true" : "false"}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                      >
                        Study India
                      </a>
                      <ul
                        className={`custom-dropdown-menu ${isHovered || isDropdownHovered ? "show" : ""}`}
                        id="custom-dropdown-li"
                        aria-labelledby="navbarDropdown"
                        onMouseEnter={handleDropdownMouseEnter}
                        onMouseLeave={handleDropdownMouseLeave}
                      >
                        <li className="dropdown-item mt-2" id="dropdown-india">
                          <a href="/mbbs-india">MBBS</a>
                        </li>
                        <li className="dropdown-item mt-2">
                          <a href="engineering-india">Engineering</a>
                        </li>
                      </ul>
                    </div>
                  </li>



                  <ul className="navbar-nav mb-lg-0">
                    <li
                      className="nav-item dropdown plx-4 border-rightfornav nav-padding"
                      onMouseEnter={handleDropdownToggle}
                      onMouseLeave={handleDropdownClose}
                    >
                      <div className="dropdown-wrapper">
                        <a href="/study-abroad"
                          className="nav-link dropdown-toggle text-white"

                          id="navbarDropdown"
                          role="button"
                          aria-expanded={dropdownOpen ? "true" : "false"}
                        >
                          Study Abroad
                        </a>
                        <ul
                          className={`dropdown-menu ${dropdownOpen ? "show" : ""
                            }`}
                          id="dropdown-li"
                          aria-labelledby="navbarDropdown"
                        >
                          <li className="dropdown-item mt-2 d-flex justify-content-between">
                            <a className="anii" href="/study-abroad">
                              Russia
                            </a>
                            <AiFillCaretRight className="icons-nav" />
                            <ul className="submenu">
                              {russiaUniversities.map(university => (
                                <li key={university.uid} className="dropdown-item mt-2">
                                  <a href={`/university/${university.slug}`}>
                                    {university.name}
                                  </a>
                                </li>
                              ))}

                              {/* <li className="dropdown-item mt-2">
                                <Link
                                  to={
                                    "/mbbs-in-bashkir-state-medical-university"
                                  }
                                >
                                  MBBS in Bashkir State Medical University
                                  Russia
                                </Link>
                              </li> */}

                            </ul>
                          </li>
                          <li className="dropdown-item mt-2 d-flex justify-content-between" id="Philippines">
                            <a className="anii" href="#">
                              Nepal
                            </a>
                            <AiFillCaretRight className="icons-nav" />

                            <ul className="submenu">
                              {nepalUniversities.map(university => (
                                <li key={university.uid} className="dropdown-item mt-2">
                                  <a href={`/university/${university.slug}`}>
                                    {university.name}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </li>
                          <li className="dropdown-item mt-2 d-flex justify-content-between" id="Philippines">
                            <a className="anii" href="#">
                              Philippines
                            </a>
                            <AiFillCaretRight className="icons-nav" />
                            <ul className="submenu">
                              <li className="dropdown-item mt-2">
                                <Link
                                  to={
                                    "/mbbs-in-brokenshire-college-of-medicine-philippines"
                                  }
                                >
                                  MBBS in Brokenshire College of Medicine
                                  Philippines
                                </Link>
                              </li>
                              <li className="dropdown-item mt-2">
                                <Link
                                  to={
                                    "/mbbs-in-our-lady-of-fatima-university-philippines"
                                  }
                                >
                                  MBBS in Our Lady of Fatima University
                                  Philippines
                                </Link>
                              </li>
                              <li className="dropdown-item mt-2">
                                <Link
                                  to={
                                    "/mbbs-in-university-of-perpetual-help-system-dalta-philippines"
                                  }
                                >
                                  MBBS in University of Perpetual Help System
                                  Dalta Philippines
                                </Link>
                              </li>
                              {/* Add more items as needed */}
                            </ul>

                          </li>

                          <li className="dropdown-item mt-2 d-flex justify-content-between" id="Philippines">
                            <a className="anii" href="#">
                              Kyrgyzystan
                            </a>
                            <AiFillCaretRight className="icons-nav" />
                            <ul className="submenu">
                              <li className="dropdown-item mt-2">
                                <Link
                                  to={
                                    "/mbbs-in-osh-state-medical-university-kyrgyzstan"

                                  }
                                >
                                  MBBS in OSH State Medical University
                                  Kyrgyzstan
                                </Link>
                              </li>
                              <li className="dropdown-item mt-2">
                                <Link
                                  to={
                                    "/mbbs-in-jalalabad-state-university-kyrgyzstan"
                                  }
                                >
                                  MBBS in Jalalabad State University Kyrgyzstan
                                </Link>
                              </li>
                             
                              {/* Add more items as needed */}
                            </ul>
                          </li>
                          <li className="dropdown-item mt-2 d-flex justify-content-between" id="Philippines">
                            <a className="anii" href="#">
                              China
                            </a>
                            <AiFillCaretRight className="icons-nav" />
                            <ul className="submenu">
                              <li className="dropdown-item mt-2">
                                <Link
                                  to={
                                    "/mbbs-in-fudan-university-shanghai-medical-college-china"
                                  }
                                >
                                  MBBS in Peking University Health Science
                                  Center China
                                </Link>
                              </li>
                              <li className="dropdown-item mt-2">
                                <Link
                                  to={
                                    "/mbbs-in-peking-university-health-science-center-china"
                                  }
                                >
                                  MBBS in Fudan University Shanghai Medical
                                  College China
                                </Link>
                              </li>
                              <li className="dropdown-item mt-2">
                                <Link
                                  to={
                                    "/mbbs-in-zhejiang-university-school-of-medicine-china"
                                  }
                                >
                                  MBBS in Zhejiang University School of Medicine
                                  China
                                </Link>
                              </li>
                              {/* Add more items as needed */}
                            </ul>
                          </li>

                          <li className="dropdown-item mt-2 d-flex justify-content-between" id="Philippines">
                            <a className="anii" href="#">
                              Bangladesh
                            </a>
                            <AiFillCaretRight className="icons-nav" />
                            <ul className="submenu">
                              <li className="dropdown-item mt-2">
                                <Link
                                  to={
                                    "/mbbs-in-dhaka-national-medical-college-bangladesh"
                                  }
                                >
                                  MBBS in Dhaka National Medical College
                                  Bangladesh
                                </Link>
                              </li>
                              <li className="dropdown-item mt-2">
                                <Link
                                  to={
                                    "/mbbs-in-eastern-medical-college-bangladesh"
                                  }
                                >
                                  MBBS in Eastern Medical College Bangladesh
                                </Link>
                              </li>
                              <li className="dropdown-item mt-2">
                                <Link
                                  to={
                                    "/mbbs-in-khwaja-yunus-ali-medical-college-bangladesh"
                                  }
                                >
                                  MBBS in Khwaja Yunus Ali Medical College
                                  Bangladesh
                                </Link>
                              </li>
                              <li className="dropdown-item mt-2">
                                <Link
                                  to={
                                    "/mbbs-in-holy-family-red-crescent-medical-college-bangladesh"
                                  }
                                >
                                  MBBS in Holy Family Red Crescent Medical
                                  College Bangladesh
                                </Link>
                              </li>


                              {/* Add more items as needed */}
                            </ul>
                          </li>
                          <li className="dropdown-item mt-2 d-flex justify-content-between" id="Philippines">
                            <a className="anii" href="#">
                              Kazakhstan
                            </a>
                            <AiFillCaretRight className="icons-nav" />
                            <ul className="submenu">
                              <li className="dropdown-item mt-2">
                                <Link
                                  to={
                                    "/mbbs-in-south-kazakhstan-medical-academy-kazakhstan"
                                  }
                                >
                                  MBBS in South Kazakhstan Medical Academy
                                  Kazakhstan
                                </Link>
                              </li>
                              <li className="dropdown-item mt-2">
                                <Link
                                  to={
                                    "/mbbs-in-kazakh-national-medical-university-kazakhstan"
                                  }
                                >
                                  MBBS in Kazakh National Medical University
                                  Kazakhstan
                                </Link>
                              </li>


                              <li className="dropdown-item mt-2">
                                <Link
                                  to={
                                    "/mbbs-in-kokshetau-state-university-kazakhstan"
                                  }
                                >
                                  MBBS in Kokshetau State University Kazakhstan
                                </Link>
                              </li>

                              {/* Add more items as needed */}
                            </ul>
                          </li>
                        </ul>
                      </div>
                    </li>
                    {/* Other menu items */}
                    {/* ... */}
                  </ul>
                {/* <li
className="nav-item dropdown plx-4 border-rightfornav nav-padding"
onMouseEnter={handleNewMouseEnter}
onMouseLeave={handleNewMouseLeave}
>
<div className="dropdown-wrapper">
  <a
            className="nav-link dropdown-toggle text-white"
    href="#"
    id="navbarDropdown"
    role="button"
    aria-expanded={newIsHovered || newIsDropdownHovered ? "true" : "false"}
    onMouseEnter={handleNewMouseEnter}
    onMouseLeave={handleNewMouseLeave}
  >
    Courses
  </a>
  <ul
    className={`custom-dropdown-menu ${newIsHovered || newIsDropdownHovered ? "show" : ""}`}
    id="custom-dropdown-li"
    aria-labelledby="newNavbarDropdown"
    onMouseEnter={handleNewDropdownMouseEnter}
    onMouseLeave={handleNewDropdownMouseLeave}
  >
     <li className="dropdown-item course mt-2" id="dropdown-india">
     <a href=""
          className="btn-link"
          onClick={() => handleCourseClick('MBBS')}
          style={{ background: 'none', border: 'none', padding: 0, margin: 0, color: 'inherit', cursor: 'pointer' }}
        >MBBS</a>
    </li>
    <li className="dropdown-item course demo mt-2">
      <a href="engineering-abroad">BDS</a>
    </li>
  </ul>
</div>
</li> */}
                  <li class="nav-item plx-4 border-rightfornav nav-padding">
                    <a
                      class="nav-link active"
                      aria-current="page"
                      href="/blogs"
                      id="naver-link"
                    >
                      Blogs
                    </a>
                  </li>


                  <li class="nav-item plx-4 border-rightfornav nav-padding">
                    <Link to={"/contact-us"}>
                      <a
                        class="nav-link active"
                        aria-current="page"
                        href="#"
                        id="naver-link"
                      >
                        Contact Us
                      </a>{" "}
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>
      </div>
    );
  };
  return (
    <nav className="Desktop_view">
      <Link to="/" className="link desktop_none">
        <img src={nayeedishalogosfin} alt="logo"></img>
      </Link>
      {!isMobile ? (
        desktopmenu()
      ) : (
        <>
          {/*  */}
          <div className=" d-flex mds ">
            <div className="divider-crddesk">
              <div className="d-flex"
                data-bs-toggle="modal"
                data-bs-target="#exampleModal"
                data-bs-whatever="@mdo"
              >


                <img src={ApplyNow} alt="Apply Online" />

                &nbsp;

              </div>
            </div>
            &nbsp;&nbsp;
            <div className="  ">
              <div className="pt-1 ph svgrs">
                <a href="tel:+91 8860031900">   <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="29"
                  fill="currentColor"
                  className="bi bi-telephone"
                  viewBox="0 0 16 16"
                >
                  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                </svg>
                </a>
                &nbsp;
              </div>
            </div>
            &nbsp;
            <div className=" ">
              <div className="pt-1 wh svgrs2">
                <a href="https://wa.me/8860031900">  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="23"
                  height="29"
                  fill="currentColor"
                  className="bi bi-whatsapp"
                  viewBox="0 0 16 16"
                >
                  <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z" />
                </svg>
                </a>
                &nbsp;
              </div>
            </div>
          </div>
          {/*  */}
          <div className="icons">
            <div
              className="mobile-menu-icon"
              onClick={() => handleToggleSidebar(true)}
            >
              <FaAlignRight />
            </div>
          </div>
        </>
      )}
    </nav>


  );


};



export default Header;
