import { React, useEffect, useState, Component, Fragment } from "react";
import aboutusbanner from '../img/aboutusbanner.png';
import { Bs0CircleFill, Bs1CircleFill, Bs2CircleFill, Bs3CircleFill, Bs4CircleFill, Bs5CircleFill, Bs6CircleFill, Bs7CircleFill, Bs8CircleFill, Bs9CircleFill } from "react-icons/bs";
import Admissionimg from '../../img/admission.svg'
import { BsGlobe } from "react-icons/bs";
import OwlCarousel from "react-owl-carousel";
import "../../../node_modules/owl.carousel/dist/assets/owl.carousel.css";
import "../../../node_modules/owl.carousel/dist/assets/owl.theme.default.css";
import { Spinner } from 'react-bootstrap';
import { Swiper, SwiperSlide } from 'swiper/react';
import lister1 from '../img/lister1.jpg';
import iconlister from '../img/iconlister.jpg';
import { Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import { Helmet } from "react-helmet";
const IndiaMBBS = () => {
  const options = {
    loop: true,
    margin: 10,
    items: 1,
    autoplay: true,
    responsive: {
      0: {
        items: 1,
        nav: true,

      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
    },
  };
  const [universities, setUniversities] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  useEffect(() => {
    fetch('https://webcrmapi.nayeedisha.in/api/university-details')
      .then((response) => response.json())
      .then((data) => {
        // Assuming 'results' contains the array of universities
        setUniversities(data);
        setIsLoading(false);
  
      })
      .catch((error) => {
        setIsLoading(false);
        console.error('Error fetching data:', error);
      });
  }, []);
  return (
    <>


<div className="application">
<Helmet>
    <meta charSet="utf-8" />
    <title>Top medical colleges in india with fees, Top medical colleges in india rank wise, best medical colleges in india for mbbs, Medical colleges in india with direct admission, list of medical colleges in India </title>
    <meta name="description" content="Get the monthly estimate for MBBS aboard around 2000 to 9000 US dollars and also, get the tuition fees on prestigious medical universities. " />
               <meta name="keywords"
                           content="Get Study MBBS on Aboard- Direction admission to top medical university in Aboard and low tuition fees for MBBS admission in aboard. " />
              
    <link rel="canonical" href="https://nayeedisha.in" />
</Helmet>

</div>
      {/* section1 */}

      <div className='abt-section pt-5 pb-5'>


        <div className='container'>

          <h3>MBBS in India</h3>

          <div className='bds'></div>
          <div id='brdrs'></div>

          {/* <p>It is a long established fact that a reader.</p> */}


        </div>


      </div>




      <div className='container abt-2 mt-5 mb-5'>


        <p className='mb-4'>

        MBBS in India is one of the most recommended courses all over the globe. When we talk about the India MBBS, you will get the best exposure from a strong medical education architecture, proficient and experienced professors, and a top-notch medical university. During the MBBS Course in India, the students will get 9 semesters and a six-month internship is compulsory. To enter the MBBS course, the student needs to qualify for the NEET exam test then, the candidate will be eligible to be a medical practitioner in India. 
The NTA (The National Testing Agency has announced the eligibility criteria for the MBBS course in India. To get the MBBS course, the student must pass class 12th  in Physics, Biology, and Chemistry. In India, MBBS courses cost approximately 25 Lakh to 1.25 Crore. Students are necessary to qualify for the NEET entrance test. Applicants will get the private or government colleges or universities based on merit on NEET.


        </p>

        <hr></hr>
        <div className='py-3 rounded'>

          <div className='container mt-3'>
            <h3 className='border_before'>Why Study an MBBS Course In India? </h3>
            <div className='row'>
              <div className='col-lg-3 col-md-6 mt-4 '>
                <div className='bg-coloringrideant card-height  p-3 rounded'>
                  <b className='f-19'>Job Stability and Security:</b>
                  <p className='f-17'>As compared to others, MBBS doctors will get stability as well as job security.</p>
                </div>

              </div>
              <div className='col-lg-3 col-md-6 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>High Demand: </b>
                  <p className='f-17'>As per the research of 2019, Indian doctors ration  18000 (people): 1. </p>
                </div>

              </div>
              <div className='col-lg-3 col-md-6 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>Multi-Dimensions Approach</b>
                  <p className='f-17'>A doctor can work in various segments such as self-private clinics, and government/private hospitals. </p>
                </div>

              </div>
              <div className='col-lg-3 col-md-6 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>Financial Rewards: </b>
                  <p className='f-17'>The medical profession creates a solid earning potential</p>
                </div>

              </div>
            </div>
          </div>

          <div className='container mb-4 mt-3'>
            <div className='row'>
            <div className='col-lg-3 col-md-6 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>



                  <b className='f-19'>Recognization</b>
                  <p className='f-17'>Indian doctors get the best class recognition or awards</p>
                </div>

              </div>
              <div className='col-lg-3 col-md-6 mt-4 '>
                <div className='bg-coloringrideant card-height p-3 rounded'>
                  <b className='f-19'>Helping others: </b>
                  <p className='f-17'>Doctors are not God, but they are no less than God because they save life.</p>
                </div>

              </div>
              
            </div>
          </div>
        </div>



        <hr></hr>




      </div>
      <div className='container'>
        <h3 className='border_before'>Major Facts About MBBS Course in India:</h3>
        <div className='row'>
          <div className='col-md-12'>
            <ul className='list-none mt-2'>
              <li className='mt-1 '><Bs1CircleFill />&nbsp; Medical universities in India are many of the most recognized or prestigious all over the globe. </li>
              <li className='mt-2 '> <Bs2CircleFill /> &nbsp; The MBBS course is designed practical as well as theoretical</li>
              <li className='mt-2 '> <Bs3CircleFill />&nbsp;  Lots of MBBS colleges in India offer a high level of expertise or clinical knowledge. </li>
              <li className='mt-2 '> <Bs4CircleFill /> &nbsp; Students will get the benefits of international seminars as well as conferences. </li>
              <li className='mt-2 '> <Bs5CircleFill /> &nbsp; MBBS syllabus got global Recognition and excellent ratings from the world-famous medical universities. </li>
              <li className='mt-2 '> <Bs6CircleFill /> &nbsp; In the current scenario, more than 72000+ seats are available in India. </li>
          
            </ul>
          </div>

        </div>
      </div>
      <hr></hr>
      <div className='container'>
        <h3 className='border_before'>Admission Process and Documents Needed</h3>
        <div className='row'>
          <div className='col-md-12'>

          </div>

        </div>
      </div>

      <div className='container'>

        <img src={Admissionimg} alt="img" className='w-100 mt-3' />
      </div>

      <br></br>
   
    </>
  );
};

export default IndiaMBBS;






